import './App.scss';
import React from 'react';
import Main from './Main';

export default function App() {

  return (
    <div className="App">
      <Main  />
    </div>
  );
}

