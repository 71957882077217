import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PrivacyPolicy from '../Components/PrivacyPolicy/PrivacyPolicy'
import TermsConditions from '../Components/TermsConditions/TermsConditions'
import HomePage from '../Components/HomePage/HomePage'



export default function Main({ navigateTo, Blogs, FaqList, WebSitePages }) {

    return (
        // <div>
        <Routes>
            <Route path="/" element={<HomePage  />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/termsconditions" element={<TermsConditions />} />
        </Routes>
        // </div>
    )
}
