import React, { useState } from 'react'
import './PrivacyPolicy.scss'
import { Helmet } from "react-helmet";

export default function PrivacyPolicy() {

    return (
        <div className="PrivacyPolicy">
            <Helmet>
                {/* <!-- TITLE --> */}
                <title>Privacy Policy | Seneer Capital</title>
                {/* <!-- META --> */}
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:image" content="article" />
                <meta property="og:url" content="https://www.seneercapital.com/privacypolicy" />
                <meta name="description" content="Protecting your privacy while using our website, product, and software is of utmost importance to us, especially when it involves conducting business or sharing sensitive personal information. This Privacy Notice serves as the governing document for our Privacy Policy, applying to all users of our website, product, and software." />
                <meta property="og:title" content="Privacy Policy | Seneer Capital" />
                <meta property="og:description" content="Protecting your privacy while using our website, product, and software is of utmost importance to us, especially when it involves conducting business or sharing sensitive personal information. This Privacy Notice serves as the governing document for our Privacy Policy, applying to all users of our website, product, and software." />
                <meta property="og:site_name" content="Privacy Policy | Seneer Capital" />
                <meta name="keywords" content="Financing, Business, Advance, Funding, Cash, Merchant, Capital, Loan, Credit, Repayment" ></meta>
            </Helmet>

            <h1><a href="/">Take me back to Home Page</a></h1>

            <div className="PrivacyPolicyHeader">
                <h1>Privacy Policy For Seneer Capital</h1>
                <h2>Updated May 11, 2023</h2>
            </div>
            <div className="PrivacyPolicyMain">

                <h3>Privacy Policy / SENEER Capital</h3>
                <h3>mobile phone information will not be shared with third parties/affiliates for marketing/promotional purposes.</h3>
                <p>Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you visit our website ("www.seneercapital.com") or use our services. By using our Site and services, you consent to the practices described in this Privacy Policy.</p>
                <h3>Information We Collect</h3>
                <p>We may collect both personally identifiable information ("PII") and non-personally identifiable information ("Non-PII") from you when you interact with our Site or use our services.</p>
                <p>PII may include but is not limited to your name, email address, postal address, phone number, and any other information you voluntarily provide to us.</p>
                <p>Non-PII may include technical information such as your IP address, browser type, device information, and website usage data.</p>

                <h3>How We Use Your Information</h3>
                <p>We use the information we collect for various purposes, including:</p>
                <ul>
                    <li>Providing and improving our services</li>
                    <li>Communicating with you</li>
                    <li>Responding to your inquiries</li>
                    <li>Sending you marketing and promotional materials (if you opt-in)</li>
                    <li>Understanding how users interact with our Site</li>
                    <li>Complying with legal obligations</li>
                </ul>

                <h3>Sale of Data</h3>
                <p>We do not sell your information you provide to us under any circumstance.</p>
                <h3>Data Security</h3>
                <p>We implement reasonable security measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.</p>

                <h3>Third-Party Services</h3>
                <p>We may use third-party services to collect, monitor, and analyze data on our behalf, such as website analytics tools. These third parties may have their privacy policies governing the use of the information they collect.</p>

                <h3>Cookies and Similar Technologies</h3>
                <p>Our Site may use cookies and similar technologies to enhance your experience, analyze usage patterns, and personalize content. You can manage your cookie preferences through your browser settings.</p>

                <h3>Links to Other Websites</h3>
                <p>Our Site may contain links to other websites that are not operated by us. We are not responsible for the privacy practices of those sites, and we encourage you to review their respective privacy policies.</p>

                <h3>Children's Privacy</h3>
                <p>Our services are not intended for children under the age of 13. We do not knowingly collect or solicit personal information from anyone under 13. If you are under 13, please do not use our services or provide any information on our Site.</p>

                <h3>Changes to This Privacy Policy</h3>
                <p>We may update this Privacy Policy from time to time. The revised policy will be posted on this page with the "Last Updated" date. We recommend checking this page periodically for any changes. Your continued use of the Site or services after any modifications will signify your acceptance of the updated Privacy Policy.</p>

                <h3>Contact Us</h3>
                <p>If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us using the information provided below:</p>
                <p>Email: info@seneercapital.com</p>
            </div>
        </div>
    )
}

