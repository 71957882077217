import './HomePage.scss';
import Bubble from '../../images/Bubble.svg'
import Robot from '../../images/Robot.png'
import Robot3 from '../../images/Robot3.png'
import RobotOnSide from '../../images/RobotOnSide.png'
import Logo from '../../images/Logo.svg'
import Close from '../../images/Close.svg'
import WebApplications from '../../images/WebApplications.svg'
import ArrowRight from '../../images/ArrowRight.svg'
import Phone from '../../images/Phone.svg'
import Email from '../../images/Email.svg'


import Zoomaya from '../../images/OurPortfoli1/Zoomaya.png'
import ZoomayaOptions from '../../images/OurPortfoli1/ZoomayaOptions.png'
import ZoomayaFilters from '../../images/OurPortfoli1/ZoomayaFilters.png'

import EarnMeCrypto from '../../images/EarnMeCrypto/EarnMeCrypto.png'
import EarnMeCryptoBanner from '../../images/EarnMeCrypto/EarnMeCryptoBanner.png'
import EarnMeCryptoHP from '../../images/EarnMeCrypto/EarnMeCryptoHP.png'

import SaveForYou from '../../images/SaveForYou/SaveForYou.png'
import SaveForYouAdd from '../../images/SaveForYou/SaveForYouAdd.png'
import SaveForYouStats from '../../images/SaveForYou/SaveForYouStats.png'

import MetalCard from '../../images/MetalCard/MetalCard.png'
import MetalCardMenu from '../../images/MetalCard/MetalCardMenu.png'
import MetalCardList from '../../images/MetalCard/MetalCardList.png'

import NftCollection from '../../images/NftCollection/NftCollection.png'
import NftCollectionBox from '../../images/NftCollection/NftCollectionBox.png'
import NftCollectionList from '../../images/NftCollection/NftCollectionList.png'

import ArenaCrypto from '../../images/ArenaCrypto/ArenaCrypto.png'
import ArenaCryptoBox from '../../images/ArenaCrypto/ArenaCryptoBox.png'
import ArenaCryptoConverter from '../../images/ArenaCrypto/ArenaCryptoConverter.png'
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha'
import React from 'react';

export default function App() {
  const [ContactUs, setContactUs] = useState(false);
  const [FormSubmitReCaptcah, setFormSubmitReCaptcah] = useState(false);


  function onChange() {
    setFormSubmitReCaptcah(true)
  }

  return (
    <div className="App">
      <div className="AppHeader">
        <div className="AppHeaderBox">
          <h1>Genesis</h1>

          <a href="#OurService"><h2>Our Services</h2></a>
          <a href="#OurPortfolio"><h2>Portfolio</h2></a>
          <a onClick={() => setContactUs(true)} href="#Contact"><h2>Contact</h2></a>
          <a href="/privacypolicy"><h2>Privacy Policy</h2></a>
          <a href="/termsconditions"><h2>Terms Conditions</h2></a>

        </div>
        <div className="AppHeaderBox">
          {/* <button onClick={() => setContactUs(true)}>Call now</button> */}
        </div>
      </div>

      <div className="AppBanner">
        <img className='Bubble' src={Bubble} alt="Web Developer" />
        <div className="AppBannerText">

          <h1> <img className='Robot' src={RobotOnSide} alt="Genesis Platforms" />Where the world <br /> builds software</h1>
          <h2>Many of clients and companies build, upgrade, and maintain <br /> their software with Genesis Platforms — the largest and <br /> most advanced development company in the world.</h2>
          <button onClick={() => setContactUs(true)} className="button-64" role="button"><span className="text">Get started</span></button>
        </div>
        <div className="Shape">
          <div className="ShapeSub"></div>
          <img className='Robot' src={Robot} alt="Web Application" />
        </div>
      </div>

      <div className="ContactUsHome">

        <div className="ContactUsMain">
          <div className="ContactUsBanner">
            <h1>Contact Us</h1>
            <h2>We Are Here For You</h2>
            <img src={Robot3} alt="Genesis Platforms contact us" />
          </div>
          <div className="ContactUsForm">
            <form action="https://formsubmit.co/info@genesisplatforms.com" method='post'>

              <div className="ContactUsFormTop">
                <div className="ContactUsFormBox">
                  <input placeholder='Name' type="text" name='name' />
                </div>
                <div className="ContactUsFormBox">
                  <input placeholder='Company' type="text" name='company' />
                </div>
              </div>
              <div className="ContactUsFormTop">
                <div className="ContactUsFormBox">
                  <input placeholder='Email' type="email" name='email' required />
                </div>
                <div className="ContactUsFormBox">
                  <input placeholder='Phone' type="tel" name='phone' required />
                </div>
              </div>
              <div className="ContactUsFormTop">
                <div className="ContactUsFormBox">
                  <textarea type="text" name='message' id="" cols="30" rows="10" placeholder='How can we help you?'></textarea>
                </div>
              </div>
              <div className="ContactUsFormTop">
                <div className="ContactUsFormBoxCheck">
                  <input type="checkbox" name="" id="" />
                  <p>I consent to receive text messages from this business.</p>
                </div>
              </div>
              <div className="ReCAPTCHA">
                <ReCAPTCHA
                  sitekey="6Ldm_e0gAAAAAEb_ZoLC9A4X2jtxlfnqOUJ7D2HF"
                  onChange={onChange}
                />
              </div>
              <input type="hidden" name='_next' value={'http://genesisplatforms.com'} />
              <input type="hidden" name='_captcha' value={false} />
              <button disabled={!FormSubmitReCaptcah} type='submit' className="button-64" role="button"><span className="text">Submit</span></button>
            </form>

          </div>
        </div>

      </div>

      <div className="GenesisTools" id='OurService'>
        <div className="GenesisToolsShape"></div>
        <h4>Our Services</h4>
        <div className="GenesisToolsBox">
          <div className="GenesisToolsBoxLineMain">
            <div className="GenesisToolsBoxLine"></div>
            <div className="GenesisToolsBoxCircle"></div>
          </div>

          <div className="GenesisToolsBoxHeader">
            <img src={WebApplications} alt="Web Applications" />
            <h1>Press Release</h1>
          </div>
          <div className="GenesisToolsBoxBody">
            <h2>We will publish a blog (landing page) about your business on 584 leading websites with over 100 million hits!</h2>
            <h2>⏫ Guaranteed organic advertising for the business! ⏫</h2>
          </div>
        </div>
        <div className="GenesisToolsBox">
          <div className="GenesisToolsBoxLineMain">
            <div className="GenesisToolsBoxLine"></div>
            <div className="GenesisToolsBoxCircle"></div>
          </div>

          <div className="GenesisToolsBoxHeader">
            <img src={WebApplications} alt="Web Applications" />
            <h1>Web Applications</h1>
          </div>
          <div className="GenesisToolsBoxBody">
            <h2>Genesis Platforms develops all types of software applications. We use proven methods for planning, building, and launching apps while continually researching and innovating to create the best solutions.</h2>
          </div>
        </div>
        <div className="GenesisToolsBox">
          <div className="GenesisToolsBoxLineMain">
            <div className="GenesisToolsBoxLine"></div>
            <div className="GenesisToolsBoxCircle"></div>
          </div>
          <div className="GenesisToolsBoxHeader">
            <img src={WebApplications} alt="Business Intelligence" />
            <h1>Business Intelligence</h1>
          </div>
          <div className="GenesisToolsBoxBody">
            <h2>Our clients need to put their data to work. Our BI processes solve their overall data needs and create their custom reporting and dashboards including embedded BI and Cloud Integration.</h2>
          </div>
          <img className='RobotOnSideTools' src={RobotOnSide} alt="Cloud Integration" />
        </div>
        <div className="GenesisToolsBox">
          <div className="GenesisToolsBoxLineMain">
            <div className="GenesisToolsBoxLine"></div>
            <div className="GenesisToolsBoxCircle"></div>
          </div>

          <div className="GenesisToolsBoxHeader">
            <img src={WebApplications} alt="Web Development software" />
            <h1>Web Development</h1>
          </div>
          <div className="GenesisToolsBoxBody">
            <h2>Our app-integrated websites help our clients stand out from the competition with superior custom designs and superb integrations from user portals to e-commerce customizations.</h2>
          </div>
        </div>
        <div className="GenesisToolsBox">
          <div className="GenesisToolsBoxLineMain">
            <div className="GenesisToolsBoxLine"></div>
            <div className="GenesisToolsBoxCircle"></div>
          </div>

          <div className="GenesisToolsBoxHeader">
            <img src={WebApplications} alt="Mobile App Development" />
            <h1>Mobile App Development</h1>
          </div>
          <div className="GenesisToolsBoxBody">
            <h2>More and more of our clients rely on mobile applications through tablets and smartphones as a way to be more productive. We are creating their solutions for these endpoints.</h2>
          </div>
        </div>
      </div>

      <div className="OurPortfolio" id='OurPortfolio'>
        <div className="OurPortfolioBorder"></div>
        <div className="OurPortfolioHeader">
          <h1>Our Portfolio</h1>
        </div>
        <div className="OurPortfolioList">
          <div className="OurPortfolioBox">
            <div className="OurPortfolioSubtotla">
              <h1>Zoomaya</h1>
              <h2>Zoomaya is a focused investigative system that shows the user’s activity step by step. It helps information security, cyber security, risk management, technical support, and site management teams, investigate events that have occurred clearly and quickly by fully monitoring the actions of users using the systems.</h2>
              <button onClick={() => setContactUs(true)}>Get A Quote</button>
            </div>
            <div className="OurPortfolioBody">
              <img src={Zoomaya} alt="Platforms" />
              <img src={ZoomayaOptions} alt="Platforms Dashboard" />
              <img src={ZoomayaFilters} alt="Software developer" />
            </div>
          </div>
          <div className="OurPortfolioBox">
            <div className="OurPortfolioSubtotla">
              <h1>Save4You</h1>
              <h2>Beyond guests getting along, there is an unofficial pecking order that traditions dictate concerning seating. Today, many couples toss these ‘rules’ aside and opt to plan seating charts in a way that allows gets to truly mingle and get to know each other during the festivities. Save4You built for make your events better.</h2>
              <button onClick={() => setContactUs(true)}>Get A Quote</button>
            </div>
            <div className="OurPortfolioBody">
              <img src={SaveForYou} alt="SaveForYou" />
              <img src={SaveForYouStats} alt="SaveForYou Dashboard" />
              <img src={SaveForYouAdd} alt="SaveForYou developer" />
            </div>
          </div>
          <div className="OurPortfolioBox">
            <div className="OurPortfolioSubtotla">
              <h1>Arena Crypto</h1>
              <h2>Buy, trade, and hold 600+ cryptocurrencies with arena's partners · Fund your account. Add funds to your crypto account to start trading crypto. Get started today and buy Bitcoin, Ethereum, ChainLink, Rune, Cardano, Binance Coin, SHIB, and more, all with some of the lowest fees in crypto</h2>
              <button onClick={() => setContactUs(true)}>Get A Quote</button>
            </div>
            <div className="OurPortfolioBody">
              <img src={ArenaCrypto} alt="Arena Crypto" />
              <img src={ArenaCryptoBox} alt="Arena Crypto Dashboard" />
              <img src={ArenaCryptoConverter} alt="Arena Crypto developer" />
            </div>
          </div>
          <div className="OurPortfolioBox">
            <div className="OurPortfolioSubtotla">
              <h1>NFT Collections</h1>
              <h2>NFTs are valuable because they verify the authenticity of a non-fungible asset. This makes these assets unique and one of a kind. Picasso's paintings are non-fungible. While anyone can make copies of his paintings, the original painting remains irreplaceable and unique. </h2>
              <button onClick={() => setContactUs(true)}>Get A Quote</button>
            </div>
            <div className="OurPortfolioBody">
              <img src={NftCollection} alt="Nft Collection" />
              <img src={NftCollectionBox} alt="Nft Collection Dashboard" />
              <img src={NftCollectionList} alt="Nft Collection solidity" />
            </div>
          </div>
          <div className="OurPortfolioBox">
            <div className="OurPortfolioSubtotla">
              <h1>Metal Card</h1>
              <h2>Create your own Custom Design or choose from one of our Best Sellers. Once you have chosen your design complete the order details. We begin working on  your card straight away. This means that when your card arrives, we can simply complete the transfer process.</h2>
              <button onClick={() => setContactUs(true)}>Get A Quote</button>
            </div>
            <div className="OurPortfolioBody">
              <img src={MetalCard} alt="Metal Card" />
              <img src={MetalCardMenu} alt="Metal Card developers" />
              <img src={MetalCardList} alt="Metal Card software" />
            </div>
          </div>
          <div className="OurPortfolioBox">
            <div className="OurPortfolioSubtotla">
              <h1>Earn Me Crypto</h1>
              <h2>Earn crypto while learning about crypto. Discover how specific cryptocurrencies work — and get a bit of each crypto to try out for yourself. Earn DeFi crypto rewards with Earn Me Crypto. Safe and secure DeFi earning opportunities for yield farmers and users who want to earn crypto rewards</h2>
              <button onClick={() => setContactUs(true)}>Get A Quote</button>
            </div>
            <div className="OurPortfolioBody">
              <img src={EarnMeCrypto} alt="Earn Me Crypto" />
              <img src={EarnMeCryptoHP} alt="Earn Me Crypto developers" />
              <img src={EarnMeCryptoHP} alt="Earn Me Crypto software" />
            </div>
          </div>
          <img src={ArrowRight} className='ArrowRight' alt="Show more web applications" />
        </div>
        <div className="OurPortfolioBorder"></div>

      </div>

      <div className="WhatWeCanDo">
        <div className="WhatWeCanDoLeft">
          <h1>What We Can <br /> <h3>Do For You</h3></h1>
          <h2>
            Our Team creates your vision by gathering information and requirements so we ensure every software application built for your business will focus on delivering your desired outcomes.
            We are driven through your satisfaction, so we listen to your ideas and create answers through your vision. Using the multiple layers of expertise within our company, we develop clear solutions for our clients to ensure the final deliverable provide the best results.
          </h2>
          <button onClick={() => setContactUs(true)} className="button-64" role="button"><span className="text">Learn More</span></button>
        </div>
        <div className="WhatWeCanDoRight">
          <img src={Robot3} alt="Genesis Software" />
          <div className="WhatWeCanDoRightCircle"></div>
          <h2>Genesis Platforms</h2>
        </div>
      </div>

      <div className="AppFooter" id='Contact'>
        <div className="AppFooterBeInTouch">
          <div className="AppFooterBeInTouchLeft">
            <h1>Stay up-to-date on latest trends and details.</h1>
          </div>
          <div className="AppFooterBeInTouchRight">
            <input type="text" />
            <button onClick={() => setContactUs(true)}>Send</button>
          </div>
        </div>
        <div className="AppFooterBody">
          <div className="AppFooterBodyMain">
            <div className="AppFooterBodyLeft">
              <h1>Genesis Platforms</h1>
              <h2>Where the world builds software</h2>
              <div className="ContactInfo" onClick={() => window.open('mailto:info@genesisplatforms.com')}>
                <div className='ContactInfoBox'>
                  <img src={Email} alt="" />
                  <h3>info@genesisplatforms.com</h3>
                </div>
                <div className='ContactInfoBox' onClick={() => window.open('tel:+17733775449')}>
                  <img src={Phone} alt="" />
                  <h3>+1 773-377-5449</h3>
                </div>
              </div>
            </div>
            <div className="AppFooterBodyRight">
              <a href="#OurService"><h2>Our Services</h2></a>
              <a href="#OurPortfolio"><h2>Portfolio</h2></a>
              <a href="/privacypolicy"><h2>Privacy Policy</h2></a>
              <a href="/termsconditions"><h2>Terms Conditions</h2></a>
              <a onClick={() => setContactUs(true)} href="#Contact"><h2>Contact</h2></a>
            </div>
          </div>
          <div className="AppFooterBodyCopyRight">
            <h6>© Copyright 2022 Genesis Platforms LLC. All Rights Reserved.</h6>
          </div>
        </div>
      </div>

      {ContactUs ?
        <div className="ContactUs">
          <div onClick={() => setContactUs(false)} className="ContactUsLightBox">

          </div>

          <div className="ContactUsMain">
            <img src={Close} onClick={() => setContactUs(false)} className='Close' alt="" />
            <div className="ContactUsBanner">
              <h1>Contact Us</h1>
              <h2>We Are Here For You</h2>
              <img src={Robot3} alt="Genesis Platforms contact us" />
            </div>
            <div className="ContactUsForm">
              <form action="https://formsubmit.co/info@genesisplatforms.com" method='post'>

                <div className="ContactUsFormTop">
                  <div className="ContactUsFormBox">
                    <input placeholder='Name' type="text" name='name' />
                  </div>
                  <div className="ContactUsFormBox">
                    <input placeholder='Company' type="text" name='company' />
                  </div>
                </div>
                <div className="ContactUsFormTop">
                  <div className="ContactUsFormBox">
                    <input placeholder='Email' type="email" name='email' required />
                  </div>
                  <div className="ContactUsFormBox">
                    <input placeholder='Phone' type="tel" name='phone' required />
                  </div>
                </div>
                <div className="ContactUsFormTop">
                  <div className="ContactUsFormBox">
                    <textarea type="text" name='message' id="" cols="30" rows="10" placeholder='How can we help you?'></textarea>
                  </div>
                </div>
                <div className="ContactUsFormTop">
                  <div className="ContactUsFormBoxCheck">
                    <input type="checkbox" name="" id="" />
                    <p>I consent to receive text messages from this business.</p>
                  </div>
                </div>
                <div className="ReCAPTCHA">
                  <ReCAPTCHA
                    sitekey="6Ldm_e0gAAAAAEb_ZoLC9A4X2jtxlfnqOUJ7D2HF"
                    onChange={onChange}
                  />
                </div>
                {/* <div class="g-recaptcha" data-sitekey="6Ldbdg0TAAAAAI7KAf72Q6uagbWzWecTeBWmrCpJ"></div> */}
                <input type="hidden" name='_next' value={'http://genesisplatforms.com'} />
                <input type="hidden" name='_captcha' value={false} />
                <button disabled={!FormSubmitReCaptcah} type='submit' className="button-64" role="button"><span className="text">Submit</span></button>
              </form>

            </div>
          </div>

        </div>
        : null}
    </div>
  );
}

